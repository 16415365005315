import * as API from '@/api/index'

export default {
    //评分类型列表
    all: params => {
        return API.POST('api/skinStandardConfig/all', params)
    },
    //8.2.创建评分类型
    create: params => {
        return API.POST('api/skinStandardConfig/create', params)
    },
    // 修改评分类型
    update: params => {
        return API.POST('api/skinStandardConfig/update', params)
    },
    // 仪器编号
    skinInstrument: params => {
        return API.POST("api/skinInstrument/list", params)
    },
    // 皮肤类型
    skinCategory: params => {
        return API.POST("api/skinCategory/all", params)
    },
    // 标准状态
    skinCondition: params => {
        return API.POST("api/skinCondition/all", params)
    },
    // 获取产品
    savingCardProduct: params => {
        return API.POST('api/savingCard/product', params)
    },
    // 获取项目
    savingCardProject: params => {
        return API.POST('api/savingCard/project', params)
    },
}